<template>
  <LiefengModal
      title="出入二维码"
      :fullscreen="fullscreen"
      :value="value"
      :toggle="toggle"
  >
    <template v-slot:contentarea>
        <Divider orientation="left">微信扫码进行出入登记</Divider>
      <div class="container">
        <div class="_left" v-if="toggleType.positionType==='2'||toggleType.positionType==='3'">
          <p>出</p>
          <img :src="textObj.signInUrl"
               alt
               ref="qrCode"
               crossorigin="anonymous"
               style="display: block;
                  margin: 0 auto;
                  width: 200px;
                  height: 200px;">
          <Button type="info" @click="downloadImgCode">下载二维码</Button>
        </div>
        <div class="_right" v-if="toggleType.positionType==='1'||toggleType.positionType==='3'">
          <p>入</p>
          <img :src="textObj.signInUrlEnter"
                alt
               ref="qrCode"
               crossorigin="anonymous"
               style="display: block;
                  margin: 0 auto;
                  width: 200px;
                  height: 200px;">
          <Button type="info" @click="downloadImgCode1">下载二维码</Button>
        </div>
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" @click="toggleFn2">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "LiefengErwma",
  props: {
    value: Boolean,
    fullscreen: Boolean,
    toggleFn1: Function,
    toggleFn2: Function,
    toggle: {
      type: Boolean
    },
    textObj: {
      type: Object
    },
    toggleType:{
      type:Object
    }
  },
  data() {
    return {}
  },
  methods: {
    downloadImgCode() {
      console.log(this.textObj,'this.textObj')
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "仅出二维码";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.textObj.signInUrl;
    },
    downloadImgCode1() {
      console.log(this.textObj, 'this.textObj')
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "仅入二维码";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.textObj.signInUrlEnter;
    },
  },
  computed: {},
  components: {
    LiefengModal
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: space-around;

  > ._left {
    margin-right: 10px;
    text-align: center;

    > p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 3px;
    }

    > Button {
      margin-top: 18px;
    }
  }

  > ._right {
    text-align: center;

    > p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 3px;
    }

    > Button {
      margin-top: 18px;
    }
  }
}
</style>
